<template>
  <div>
    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="show {first} to {last} from {totalRecords}"
    >
      <template #header>
        <div class="table-header">
          Massages Aufführen

          <div style="float: right">
            <Button
              icon="pi pi-download"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </div>
        </div>
      </template>
      <template #empty>Keine Daten</template>
      <template #loading>Daten werden geladen, bitte warten... </template>
      <Column
        filterMatchMode="contains"
        field="id"
        header="ID"
        :sortable="true"
      >
      </Column>

      <Column
        filterMatchMode="contains"
        field="name"
        header="Name"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ slotProps.data.fname + " " + slotProps.data.lname }}
        </template>
      </Column>
      <Column
        filterMatchMode="contains"
        field="date"
        header="Date"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ $durationFormat(slotProps.data.start) }}
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="time"
        header="Time"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ $durationFormatFull(slotProps.data.start, slotProps.data.end) }}
        </template>
      </Column>

      <Column field="paymentMethod" header="Type" :sortable="true">
        <template #body="slotProps">
          <span v-if="slotProps.data.paymentMethod == 'cash'">
            Externe Gast - Cash
          </span>
          <span v-if="slotProps.data.paymentMethod == 'online'">
            Externe Gast - Online
          </span>
          <span v-if="slotProps.data.paymentMethod == 'hotel'">
            Hotel Gäste
          </span>
          <span v-if="slotProps.data.paymentMethod == 'Gift'">
            Gutschein Einlösung
          </span>
          <span v-if="slotProps.data.paymentMethod == 'Package'">
            Angebot benutzen
          </span>
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="paymentDone"
        header="Payment Done"
        :sortable="true"
      >
        <!-- <template #filter>
              <Calendar
                class="p-column-filter"
                style="width: 100%;"
                v-model="filters['date']"
                dateFormat="dd-mm-yy"
                placeholder="Search By Date"
              />
            </template> -->
        <template #body="slotProps">
          {{ slotProps.data.paymentDone ? "Done" : "--" }}
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="id"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <Button
            type="button"
            @click="$router.push('/admin/massageorder/edit/' + slotProps.data.id)"
            icon="pi pi-eye"
            class="p-button-info p-mr-2 p-button-rounded"
          ></Button>
          <!-- <Button
                type="button"
                icon="pi pi-trash"
                v-if="slotProps.data.id != 1"
                @click="deleteItem(slotProps.data.id)"
                class="p-button-warning  ml-2  p-button-rounded"
              ></Button> -->
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],

      loading: true,
    };
  },
  methods: {},
  created() {
   const id=this.$route.params.id;
    this.$http.get(`appointments?usersId=${id}`).then((res) => {
      this.loading = false;
      this.list = res.data.data;
    });
  },
};
</script>
